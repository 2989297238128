body {
    background: linear-gradient(to bottom, #bc1893, #ffffff);
    /* Light pink to white gradient */
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    overflow-x: hidden;
    font-family: 'Comic Neue', sans-serif;
    /* Use the cute font */
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    position: absolute;
    top: 20px;
    left: 0;
}

.social-icon {
    width: 40px;
    height: 40px;
    transition: transform 0.3s ease;
    fill: #FFFFFF;
}

.social-icon:hover {
    transform: scale(1.1);
}

.mascot-container {
    position: relative;
    /* Position relative for absolute positioning of text */
}

.mascot {
    width: 400px;
    height: 400px;
    margin-top: 20px;
    /* Adjust margin to center the mascot */
    object-fit: contain;
    cursor: pointer;
    animation: float 3s ease-in-out infinite;
    /* Ensure the same animation timing */
}

@keyframes float {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0px);
    }
}

.floating {
    animation: float 3s ease-in-out infinite;
}

.ca-field {
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the CA field */
    margin-bottom: 20px;
    font-size: 1.2rem;
    color: #FFFFFF;
}

.copy-icon {
    margin-left: 10px;
    cursor: pointer;
    color: #FFFFFF;
    transition: transform 0.3s ease;
}

.copy-icon:hover {
    transform: scale(1.1);
}

.japanese-text {
    position: absolute;
    top: 10px;
    right: 10px;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 2rem;
    color: #FFFFFF;
    animation: float 3s ease-in-out infinite;
    /* Add float animation */
    transform: rotate(15deg);
    /* Increase the rotation angle for more visibility */
    transform-origin: top right;
    /* Set the origin for rotation */
}

.marquee-container {
    position: absolute;
    top: 200px;
    /* Add units */
    right: -20px;
    /* Add units */
    width: 600px;
    /* Adjust width as needed */
    overflow: hidden;
    transform: rotate(45deg);
    /* Rotate to wrap around the corner */
    transform-origin: top right;
    color: wheat;
    z-index: 1000;
    /* Ensure it's on top */
    background-color: rgba(98, 21, 86, 0.5);
    /* Temporary background for visibility */
}

.marquee {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 10s linear infinite;
}

@keyframes marquee {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}



.temp-image {
    width: 200px;
    /* Adjust size as needed */
    height: 200px;
    position: fixed;
    /* Use fixed to position relative to the viewport */
    z-index: 10;
    /* Ensure they are above other elements */
    animation: rotateBackAndForth 4s infinite alternate ease-in-out;
    /* Apply animation */
}

@keyframes rotateBackAndForth {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(20deg);
    }

    100% {
        transform: rotate(-20deg);
    }
}

.top-left {
    top: 50px;
    /* Add some margin from the edges */
    left: 50px;
}

.top-right {
    top: 50px;
    right: 50px;
    z-index: 3;
}

.bottom-left {
    bottom: 50px;
    left: 50px;
}

.bottom-right {
    bottom: 50px;
    right: 50px;
}



.floating-text {
    position: absolute;
    font-size: 2rem;
    color: rgba(255, 255, 255, 0.7);
    filter: blur(2px);
    animation: floatText 10s ease-in-out infinite;
    white-space: nowrap;
    z-index: 1;
    /* Ensure text is behind other elements */
}

.text1 {
    top: 15%;
    left: 20%;
}

.text2 {
    top: 50%;
    right: 15%;
}

.text3 {
    bottom: 10%;
    left: 20%;
}

@keyframes floatText {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0);
    }
}